import {Alert, Autocomplete, Box, Button, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ContactData, DocumentDescriptor, DocumentFieldDescriptor, DocumentFieldType} from "./DocumentData";
import {ePismoClientInstance} from "../Service/EPismoClient";
import {useKeycloak} from "@react-keycloak/web";
import {DateField, DatePicker, DateTimePicker, plPL} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import CircleRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';

export default function CreateDocumentStep2(props: { callback: any, documentTypeId: Number }) {

    const [descriptor, setDescriptor] = useState<DocumentDescriptor>({'name': '', 'fields': []})
    const [contactData, setContactData] = useState<ContactData[]>([])
    const [data, setData] = useState({})
    const [validationMessage, setValidationMessage] = useState("")
    const [contactDataQuery, setContactDataQuery] = useState("")
    const [contactDataId, setContactDataId] = useState("");
    const [loading, setLoading] = useState(false);

    const keycloak = useKeycloak();

    useEffect(() => {
        ePismoClientInstance.get("/api/v1/gui/documents/addresses?query=" + contactDataQuery + "&documentType=" + props.documentTypeId, keycloak).then(resp => {
            setContactData(resp.data)
        })
    }, [contactDataQuery]);
    useEffect(() => {
        if (props.documentTypeId != null) {
            ePismoClientInstance.get("/api/v1/gui/documents/types/" + props.documentTypeId, keycloak).then(resp => setDescriptor(resp.data))
        }
    }, [props.documentTypeId]);

    const onChange = (field: string, e: any) => {
        setData(prev => ({...prev, [field]: e}))
    }

    const send = () => {
        let document = ({...data, to: contactDataId})
        let invalidateFields = descriptor.fields
            .filter(f => f.required)
            .filter(f => (!(document as any)[f.key] || (document as any)[f.key] === 'Invalid Date'))
            .map(f => f.label);
        if (!contactDataId) {
            invalidateFields.push("Odbiorca")
        }
        if (invalidateFields.length > 0) {
            let message = 'Następujące pola są wymagane: ' + invalidateFields.join(', ')
            setValidationMessage(message)
        } else {
            setValidationMessage("")
            setLoading(true)
            let request = {
                documentType: props.documentTypeId,
                to: contactDataId,
                content: data
            }
            ePismoClientInstance.post("/api/v1/gui/documents", keycloak, request)
                .then(r => props.callback(r.data.signature))
                .catch(r => "Błąd podczas generowania dokumentu. Spróbuj ponownie później")
                .finally(() => setLoading(false))
        }
    }

    return (
        <Box className="Step2">
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <Typography align="left" variant="h1"><span>2/3</span> Wypełnij formularz
                        - <span>{descriptor.name}</span></Typography>
                </Grid>
                <Grid xs={12}>
                    <div className="autocomplete">
                        <Autocomplete
                            filterOptions={(x) => x}
                            onInputChange={e => {
                                setContactDataQuery((e.target as HTMLInputElement).value)

                            }}
                            onChange={(e, v) => setContactDataId(v ? v.id : "")}
                            noOptionsText="Nie znaleziono"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Odbiorca"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />)}
                            getOptionLabel={o => o.name + " - " + o.address}
                            renderOption={(props, data) => <li {...props}>
                                <Grid container  columns={20}>
                                    <Grid item xs={2} md={1}>
                                        {data.filledEmail && <CircleRoundedIcon fontSize="small" color="success"/>}
                                        {!data.filledEmail && <CircleRoundedIcon fontSize="small" color="warning"/>}
                                    </Grid>
                                    <Grid item xs={18} md={19}>
                                        <span>{data.name} </span>
                                        <Typography variant="body2" color="text.secondary" sx={{textAlign:"left"}}>{data.address}</Typography>
                                    </Grid>
                                </Grid>
                            </li>}
                            options={contactData}/>
                    </div>
                    {descriptor.fields.map(f => {
                            return (<>
                                    {f.type === DocumentFieldType.TEXT &&
                                        <TextField size="small" fullWidth label={f.label} variant="outlined"
                                                   onBlur={e => onChange(f.key, e.target.value)}/>}
                                    {f.type === DocumentFieldType.TEXT_AREA &&
                                        <TextField multiline rows={8} maxRows={12} size="small" fullWidth label={f.label} variant="outlined"
                                                   onBlur={e => onChange(f.key, e.target.value)}/>}
                                    {f.type === DocumentFieldType.DATE && <DatePicker
                                        localeText={plPL.components.MuiLocalizationProvider.defaultProps.localeText}
                                        slotProps={{ textField: { size: 'small' } }}
                                        label={f.label}
                                        onChange={e => onChange(f.key, e != null ? (e as Dayjs).format('YYYY-MM-DD') : null)}
                                        format="DD.MM.YYYY"/>}
                                    {f.type === DocumentFieldType.NUMBER &&
                                        <TextField size="small" type="number" fullWidth label={f.label}
                                                   onBlur={e => onChange(f.key, e.target.value)} variant="outlined"/>}
                                </>
                            )
                        }
                    )}
                        <Button size="large" fullWidth variant="contained" onClick={e => send()} disabled={loading}>
                            {loading && <>Trwa generowanie pisma... <CircularProgress size={20} sx={{ml: 1}} /></>}
                            {!loading && <>Nadaj pismo</>}
                        </Button>
                    {validationMessage && <Alert severity="error">{validationMessage}</Alert>}
                </Grid>
            </Grid>
        </Box>
    )
}